import React from 'react';
import { styled } from '@stitches/react';
import { violet, blackA, mauve } from '@radix-ui/colors';
import {
    StrikethroughIcon,
    TextAlignLeftIcon,
    TextAlignCenterIcon,
    TextAlignRightIcon,
    FontBoldIcon,
    FontItalicIcon,
} from '@radix-ui/react-icons';
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import { Link } from "react-router-dom";

const StyledToolbar = styled(ToolbarPrimitive.Root, {
    display: 'flex',
    padding: 10,
    width: '100%',
    minWidth: 'max-content',
    borderRadius: 6,
    backgroundColor: 'white',
    boxShadow: `0 2px 10px ${blackA.blackA7}`,
});

const itemStyles = {
    all: 'unset',
    flex: '0 0 auto',
    color: mauve.mauve11,
    height: 25,
    padding: '0 5px',
    borderRadius: 4,
    display: 'inline-flex',
    fontSize: 13,
    lineHeight: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': { backgroundColor: violet.violet3, color: violet.violet11 },
    '&:focus': { position: 'relative', boxShadow: `0 0 0 2px ${violet.violet7}` },
};

const StyledButton = styled(
    ToolbarPrimitive.Button,
    {
        ...itemStyles,
        paddingLeft: 10,
        paddingRight: 10,
        color: 'white',
        backgroundColor: violet.violet9,
    },
    { '&:hover': { color: 'white', backgroundColor: violet.violet10 } }
);

const StyledLink = styled(
    ToolbarPrimitive.Link,
    {
        ...itemStyles,
        backgroundColor: 'transparent',
        color: mauve.mauve11,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    { '&:hover': { backgroundColor: 'transparent', cursor: 'pointer' } }
);

const StyledSeparator = styled(ToolbarPrimitive.Separator, {
    width: 1,
    backgroundColor: mauve.mauve6,
    margin: '0 10px',
});

const StyledToggleGroup = styled(ToolbarPrimitive.ToggleGroup, {
    display: 'inline-flex',
    borderRadius: 4,
});

const StyledToggleItem = styled(ToolbarPrimitive.ToggleItem, {
    ...itemStyles,
    boxShadow: 0,
    backgroundColor: 'white',
    marginLeft: 2,
    '&:first-child': { marginLeft: 0 },
    '&[data-state=on]': { backgroundColor: violet.violet5, color: violet.violet11 },
});

// Exports
export const Toolbar = StyledToolbar;
export const ToolbarButton = StyledButton;
export const ToolbarSeparator = StyledSeparator;
export const ToolbarLink = StyledLink;
export const ToolbarToggleGroup = StyledToggleGroup;
export const ToolbarToggleItem = StyledToggleItem;

const ToolbarDemo = () => (
    <Toolbar aria-label="Formatting options">
        <ToolbarLink href="#" target="_blank" css={{ marginRight: 10 }}>
            <Link to="/" style={{
                marginRight: "10px"
            }}>Main</Link>
            <Link to="/login" style={{
                marginRight: "10px"
            }}>Login</Link>
            <Link to="/com">ComTest</Link>
        </ToolbarLink>
        <ToolbarButton css={{ marginLeft: 'auto' }}>Share</ToolbarButton>
    </Toolbar>
);

export default ToolbarDemo;