import './App.css';
import NavBar from './components/Visual/Navbar'


function App() {
  return (
    <div className="App" style={{
      backgroundColor: "#5D00FF",
      height: "100vh"
    }}>
      <NavBar /> 
    </div>
  );
}


export default App;
