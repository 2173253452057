import GetItems from './GetItems'

const apiKey = "at_JugAQjojb7iJxSZiLZqJedMO1PVE"
const domains = "google.com,keeblink.com"
const serviceurl = "https://api.sandbox.namecheap.com/xml.response"
const apiUser = ""
const apiNCName = "ncuser"
const apiCommand = "namecheap.domains.check&DomainList="

function componentDidMount() {
  fetch(serviceurl + "?ApiUser=" + apiUser + "&ApiKey=" + apiKey + "&UserName=" + apiNCName + "?Command=" + apiCommand + domains)
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
      },
      (error) => {
        console.log(error)
      }
    )
}

export default componentDidMount