import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import  { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginSignUp from './routes/LoginSignUp'
import DotCOM from './routes/DotCOM'

render(
  <BrowserRouter>
     <Routes>
      <Route path="/" element={<App />} />
      <Route path="login" element={<LoginSignUp />} />
      <Route path="com" element={<DotCOM/>} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
reportWebVitals();
