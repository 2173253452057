import componentDidMount from '../components/CheckAvailDomains';
import NavBar from '../components/Visual/Navbar'

export default function Invoices() {
    return (
        <div>
            <NavBar />
            <Button/>
        </div>
    );
}

function Button() {
    return (
        <button style={{
            backgroundColor: "white"
        }} onClick={componentDidMount}>
            Test
        </button>
    )
}